.game{
    display:flex;
    background: transparent;
    height: 100vh;
    width: 100vw;    
    justify-content: center;
}
html, body{
    background: transparent;
}
.overlay{
    z-index: 100;
}
@media screen and (max-width:900px) {
    .game {
      flex-direction: column-reverse;
    }
}