/* .item must be first */
.item {
    align-items: center;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  .page-item {
    color: #3d0dfd;
  }
  .page-item .page-link{
    border-radius: 100%;
    margin:0.25em;
    height: 40px;
    width: 40px;
  }
  .page-item.active .page-link{
    background-color:#3d0dfd;    
    border-radius: 100%;
  }
  .disabled-page {
    color: #3d0dfd;
  }
  .page-link{
    color:white;
    background-color:#6770FC;
    padding:.25rem .8rem;
  }
  .active {
    border-radius: 40px;
    color: #3d0dfd;
  }
  
  .break-me {
  }
  
  .n {
    border-right: solid 1px #3d0dfd;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
  }
  
  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
    border-radius: 8px;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  .page-item:first-child .page-link{
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
  .page-item:last-child .page-link{
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
  }
  .p {
    border-left: solid 1px  #3d0dfd;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
  }
